/**
 * PrimeNG overrides
 */

/**
 * corrections allowing for fluid width
 */
input {
   &.p-inputtext:not(.p-inputtext-fluid) {
      width: auto;
   }

   &.p-inputtext-fluid {
      max-width: unset;
   }
}
